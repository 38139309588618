(function () {

    !function (t, n) {
        t[n] = t[n] || {
            nlsQ: function (e, o, c, r, s, i) {
                return s = t.document, r = s.createElement("script"), r.async = 1, r.src =
                    ("http:" === t.location.protocol ? "http:" : "https:") + "//cdn-gl.imrworldwide.com/conf/" +
                    e + ".js#name=" + o + "&ns=" + n, i = s.getElementsByTagName("script")[0],
                    i.parentNode.insertBefore(r, i), t[n][o] = t[n][o] || {
                    g: c || {},
                    ggPM: function (e, c, r, s, i) {
                        (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i])
                    }
                }, t[n][o]
            }
        }
    }(window, "NOLBUNDLE");

    // SDK Initialization
    const nSdkInstance = NOLBUNDLE.nlsQ("P20A3EBF2-72B9-4C1B-B261-2DD0A8DB0509", "nlsnInstance");

    function getAssetIdByUrl(){
        var assetid = "";
        var hostname = window.location.hostname;
        var pathname = window.location.pathname;
        if(hostname && hostname !== null) {
            assetid = hostname.split("?")[0].toLowerCase();
            if(pathname && pathname !== null) {
                assetid = assetid+''+pathname.split("?")[0].toLowerCase();
            }
            try{
                var regExp = /[&\/\\#, +()$@~%.'":*?<>{}]/ig;
                return assetid.replace(regExp,"-");
            }catch(e){
                return "no_content";
            }
        }
        return "no_content";

    }

    // Content Metadata
    const nielsenMetadata = {
        type: 'static',
        assetid: ''+getAssetIdByUrl()+'', // *DYNAMIC METADATA*: unique ID for each article **REQUIRED**
        section: 'GameSurf_BRW' // *DYNAMIC METADATA*: section of site **REQUIRED**
    };

    // Event 'staticstart' Call
    nSdkInstance.ggPM("staticstart", nielsenMetadata);

})()

